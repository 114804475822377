
// import "./bit";
import "./insta";
import "magnific-popup";


jQuery( document ).ready(function($) {
    $('.scroll').on('click', function(e) {
        const TARGET = $(this).attr('href'); 
    
        e.preventDefault();
    
        $('body, html').animate({
            scrollTop: $(TARGET).offset().top
        });
    });

    $('.more-btn').on('click', function(e){
        e.preventDefault();
        $('.more').slideToggle();
    }); 

    $('#feed').juicer();

    // $('#dates').bit({
    //     'artist': 'Tigirlily', 
    //     show_btn: true,
    //     show_y: false,
    //     show_rsvp: false,
    //     tickets_msg : 'Tickets',
    //     template    : $(`
    //         <div class="event">
    //             <div class="date">
    //                 <span class="dow"></span>
    //                 <span class="month"></span>
    //                 <span class="day"></span>
    //                 <span class="year"></span>
    //             </div>
    //             <div class="location">
    //                 <span class="city">,</span>
    //                 <span class="region">,</span>
    //                 <span class="country"></span>
    //             </div>
    //             <div class = "location2">
    //                 <span class="venue"></span>
    //             </div>
    //             <div class="tickets"></div>
    //         </div>
    //     `),
    // });

    $.ajax( {
        url: 'https://rest.bandsintown.com/artists/Tigirlily/events?app_id=45PRESS_tigirlily',
        method: 'GET',
        dataType: 'json',
        error: () => { 
            alert( 'Error fetching events!' );
        },
        success: data => {
            const events = $('#dates');
            let html = '';
            if ( data.length ) {
                for ( let event of data ) {
                    let location = event.venue.city + ', ' + event.venue.region;

                    if ( location === ', ' ) {
                        location = '';
                    }

                    html += '<div class="event">';
                    html += '<div class="date">' + moment( event.datetime ).format( 'MMM D' ) + '</div>';
                    html += '<div class="location">' + location + '</div>';
                    html += '<div class="location2">' + event.venue.name + '</div>';
                    html += '<div class="tickets">';
                    for ( let offer of event.offers ) {
                        html += '<a href="' + offer.url + '" target="_blank" class="btn tickets-link">'+offer.type+'</a>';
                    }
                    html += '</div>';
                    html += '</div>'; 
                }

                if ( data.length > 7 ) {
                    html += '<div class="tour-more-wrap"><a href="#" class="tour-more">View More</a></div>'
                }
                
                events.html( html );

                $('.tour-more').on('click', function(e){
                    e.preventDefault();
                    $('#dates').toggleClass('seeall');
                })
            } else {
                events.html( 'No upcoming events.' );
            }
        }
    } );

    $('.menu-toggle').on('click', function(){
        $('.nav').toggleClass('open')
        $('.menu-toggle').toggleClass('open')
    });

    if ($('#popup').length){
        $.magnificPopup.open({
            items: {
            src: '#popup',
            type: 'inline'
            }
        });
    }
}); 